import { UploadProps } from 'antd';
import { useState, useEffect, FocusEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { alertError, alertSuccess, alertSuccessPopup, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { ERROR_MESSAGE, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';

export type ReceivedProps = {
  screenType: "create" | "edit" | "detail";
};
const useQuoteForm = (props: ReceivedProps) => {
  const { screenType } = props;
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
    setError,
  } = useForm();
  const [isAllowUpload, setAllowUpload] = useState(true);
  const [fileLength, setFileLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [partnerOptions, setPartnerOptions]: any = useState([])
  const id = useParams();
  const navigate = useNavigate();
  const [listFile, setListFile]: any = useState([]);
  let storage = localStorage.getItem('user');
  const user = storage !== null ? JSON.parse(storage) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [totalImageSize, setTotalImageSize] = useState<number>(0);
  const [isDisable, setDisable] = useState(false)
  const [currentCompany, setCurrentCompany]: any = useState()
  const [companyHolder, setCompanyHolder] = useState('')
  const dragProps: UploadProps = {
    name: "file",
    onChange(info) {
      console.log("onChange info", info);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const dummyRequest: UploadProps["customRequest"] = async ({
    file,
    onSuccess
  }) => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess("ok");
      }
    }, 0);
  };


  const handleClose = () => {
    clearErrors();
    setTotalImageSize(0);
    navigate('/quote/list');
  };

  const submitData = async (value: any, evt: any) => {
    // evt.preventDefault()
    if (value?.zipCode) {
      const isZipcodeFormat = validateZipcode(value?.zipCode);
      if (!isZipcodeFormat) {
        setError('zipCode', {
          type: 'validate',
          message:
            '郵便番号は数字のみで、000-0000の形式でなければなりません。',
        });
        return;
      }
    }

    if (value?.zipCodeConstruction) {
      const isZipcodeFormat = validateZipcode(value?.zipCodeConstruction);
      if (!isZipcodeFormat) {
        setError('zipCodeConstruction', {
          type: 'validate',
          message:
            '郵便番号は数字のみで、000-0000の形式でなければなりません。',
        });
        return;
      }
    }
    setIsLoading(true);
    let formData = new FormData();
    formData.append('title', '');
    for (let i = 0; i < value?.images.length; i++) {
      formData.append('images[]', value?.images[i].originFileObj);
    }

    formData.append('note', value?.note || '');
    formData.append('status', value?.status);
    formData.append('quote_type', value?.quote_type);
    formData.append('desired_date', value?.dateTime);
    formData.append('zipcode', value?.zipCodeConstruction);
    formData.append('address', value?.construction_address);
    formData.append('content', value?.content);
    formData.append('project_title', value?.project_title);
    formData.append('customer[first_name]', value?.first_name);
    formData.append('customer[last_name]', value?.last_name);
    formData.append('customer[first_name_katakana]', value?.first_name_kana);
    formData.append('customer[last_name_katakana]', value?.last_name_kana);
    formData.append('customer[address]', value?.address);
    formData.append('customer[zipcode]', value?.zipCode);
    formData.append('customer[phone_number]', value?.phone);
    value?.cooperative_company_id && formData.append('cooperative_company_id', value?.cooperative_company_id)
    try {
      if (screenType === "create") {
        const res = await quoteApi.createQuote(user?.access_token, formData);
        if (res) {
          alertSuccess(
            '見積依頼内容の更新に成功しました。'
          );
        }
      } else {
        const res = await quoteApi.updateQuote(user?.access_token, formData, id.id);
        if (res) {
          alertSuccessPopup({
            title: '見積依頼内容の更新に成功しました。',
            confirmButtonText: '閉じる',
          });

          setIsLoading(false);
          navigate(`/quote/list`);
          setValue('note', '');
          setValue('title', '');
          setValue('project_title', '');
          setValue('address', '');
          setValue('images', '');
          setListFile([]);
          setTotalImageSize(0);
          setValue('zipcode', '');
          setValue('desired_date', undefined);
          setValue('content', '');
        }
      }
      handleClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getPartners = async (company?) => {
    try {
      const res = await quoteApi.getAllPartner(user?.access_token)
      if (res) {
        const data = res?.data?.cooperative_companies
        let arrTmp: any = []
        arrTmp = data?.map((item) => ({
          value: item.id,
          name: item.company_name,
          label: item.company_name
        }))
        if (id?.id && company) {
          if (data.length > 0) {
            const test = data.filter((res: any) => res.id === company.id)
            if (test && test.length === 0) {
              setValue('cooperative_company_id', undefined)
              setCompanyHolder(company.company_name)
              // arrTmp.unshift({
              //   value: company.id,
              //   name: company.company_name,
              //   label: company.company_name
              // })
            } else {
              setCompanyHolder('')
            }
          }
        }
        setPartnerOptions(arrTmp)
      }
    } catch (error) {
      console.log('error', error)
      alertError(ERROR_MESSAGE)
    }
  }

  const getDetail = async () => {
    setLoading(true);
    try {
      const res = await quoteApi.getQuoteDetail(user?.access_token, id.id);
      if (res) {
        const data = res.data.quote;
        setCurrentCompany(data?.cooperative_company)
        if (data?.quote_type === null) {
          setValue('quote_type', 'interior');
        } else {
          setValue('quote_type', data?.quote_type);
        }
        setValue('title', data?.title);
        setValue('note', data?.note);
        setValue('construction_address', data?.address);
        setValue('status', data?.status);
        setValue('content', data?.content);
        setValue('zipCode', data?.customer?.zipcode);
        setValue('zipCodeConstruction', data?.zipcode);
        setValue('project_title', data?.project_title);
        setValue('first_name', data?.customer?.first_name);
        setValue('last_name', data?.customer?.last_name);
        setValue('first_name_kana', data?.customer?.first_name_katakana);
        setValue('last_name_kana', data?.customer?.last_name_katakana);
        setValue('address', data?.customer?.address);
        setValue('phone', data?.customer?.phone_number);
        setValue('cooperative_company_id', data?.cooperative_company_id)
        data?.desired_date &&
          setValue('dateTime', moment(data.desired_date));
        const fileArr: any = [];
        let imageSize = 0;
        if (data?.images.length > 0) {
          for (let i = 0; i < data?.images.length; i++) {
            await fetch(data?.images[i].image_path)
              .then((res) => res.blob())
              .then((myBlob) => {
                const myFile: any = new File(
                  [myBlob],
                  'image.jpeg',
                  { type: myBlob.type },
                );
                fileArr.push({
                  uid: `-${i + 1}`,
                  name: myFile.name,
                  url: data?.images[i].image_path,
                  status: 'done',
                  originFileObj: myFile,
                  lastModified: myFile.lastModified,
                  lastModifiedDate: myFile.lastModifiedDate,
                  type: myFile.type,
                  size: myFile.size,
                });
                imageSize += myFile.size;
              });
          }
        }
        setValue('images', fileArr);
        setTotalImageSize((prev) => prev + imageSize);
        setListFile(fileArr);
        setFileLength(fileArr.length);
        setAllowUpload(data?.images?.length < 10);
        getPartners(data?.cooperative_company)
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const handleValidateWhitespace: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
    if (onlyContainWhitespaces) {
      setValue(e.target.name, '');
    } else {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  useEffect(() => {
    if (id?.id) {
      getDetail();
    } else {
      getPartners()
    }
  }, [id?.id]);

  // useEffect(() => {
  //   if (id?.id && currentCompany) {
  //     console.log('first here', currentCompany)
  //     if (partnerOptions.length > 0) {
  //       const test = partnerOptions.filter((res: any) => res.value === currentCompany.id)
  //       console.log('test', test)
  //     }
  //   }
  // }, [currentCompany, id?.id])

  useEffect(() => {
    setValue('quote_type', 'interior');
    setValue('status', 'default');
  }, []);

  useEffect(() => {
    if (screenType === TYPE_OF_SCREEN.detail) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [])

  return {
    ...props,
    handleSubmit,
    submitData,
    control,
    dragProps,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    handleValidateWhitespace,
    screenType,
    partnerOptions,
    isDisable,
    companyHolder
  };
};

export type Props = ReturnType<typeof useQuoteForm>;

export default useQuoteForm;
