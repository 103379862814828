import styled from 'styled-components';

export const QuoteFormStyles = styled.div`
    background-color: #e3e6e8;
    height: 100%;
    input {
    height: 42px;
    }
    select {
    height: 42px;
    }
    .ant-select-selection-placeholder {
    color: #000 !important
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
        box-shadow: none !important;
    }
    .ant-select-selector {
        box-shadow: none !important;
    }
    .quote-form-container {
        width: calc(100%);
        background: #fff;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        height: 100%;

        .loadingSpinWrapper {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-spin-dot {
            font-size: 50px;
    
            .ant-spin-dot-item {
              width: 24px;
              height: 24px;
            }
          }
        }

        .textBox {
            width: 100%;
            gap: 8px;
            padding: 10px 12px;
            width: 100%;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 2px;
        }

        .select-search {
            .select-search__input {
                border: 1px solid #cdd1d5;
                box-shadow: none;

                &:hover {
                    border-color: #215493;
                }
            }

            .select-search__select {
                .select-search__options {
                    padding-left: 0 !important;
                    .select-search__row {
                        .select-search__option.is-selected {
                            background: #215493 !important;
                        }
                    }
                }
            }
        }
    }
`;
